// https://github.com/nuxt/framework/issues/6711

export enum ArticleBlocks {
  ArticleCtaRecord = 'SectionArticleCta',
  BodyRecord = 'SectionArticleBody',
  SectionQuickGuideRecord = 'SectionQuickGuides'
}

export enum DiscoverBlocks {
  SectionArticleListRecord = 'SectionArticles',
  SectionDiscoverCategoryListRecord = 'SectionDiscoverCategories',
  SectionNewsletterRecord = 'SectionNewsletter',
  SectionFeaturedArticleRecord = 'SectionFeaturedArticles',
  SectionTopStoryListRecord = 'SectionTopStories'
}

export enum HelpTopicBlocks {
  SectionFaqRecord = 'SectionFaq',
  SectionHelpLinkRecord = 'SectionHelpLinks',
  SectionRelatedHelpArticleListRecord = 'SectionRelatedHelpArticles',
  SectionHelpCategoryListRecord = 'SectionHelpQuestions'
}

export enum IndexHeroBlocks {
  SectionHeroFullscreenRecord = 'SectionHeroFullscreen',
  SectionHeroRecord = 'SectionHero'
}

export enum IndexBlocks {
  SectionArticleListRecord = 'SectionArticles',
  SectionBenefitsListRecord = 'SectionBenefits',
  SectionCardsSliderRecord = 'SectionCards',
  SectionCtaRecord = 'SectionCta',
  SectionDiscoverMoreRecord = 'SectionDiscoverMore',
  SectionEducationalRecord = 'SectionEducational',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionGraphRecord = 'SectionGraph',
  SectionImmersiveTitleRecord = 'SectionImmersiveTitle',
  SectionLogosListRecord = 'SectionLogos',
  SectionMediaFullscreenRecord = 'SectionMediaFullscreen',
  SectionPartnerRecord = 'SectionPartner',
  SectionFeaturedArticleRecord = 'SectionFeaturedArticles',
  SectionTableRecord = 'SectionTable',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionVideoRecord = 'SectionVideo'
}

export enum PageHeroBlocks {
  SectionCompareAssetRecord = 'SectionCompareAssets',
  SectionCryptoMarketRecord = 'SectionCryptoMarket',
  SectionHeroBasicRecord = 'SectionHeroBasic',
  SectionDiscoverHeroRecord = 'SectionDiscoverHero',
  SectionHeroRecord = 'SectionHero',
  SectionHeroFullscreenRecord = 'SectionHeroFullscreen',
  SectionHeroRocketRecord = 'SectionHeroRocket',
  SectionGlossaryRecord = 'SectionGlossary',
  SectionFormRecord = 'SectionForm',
  SectionPageRecord = 'SectionPage',
  SectionHeroContactRecord = 'SectionHeroContact',
  SectionSearchRecord = 'SectionSearch',
  SectionSearchTopicRecord = 'SectionSearchTopics',
  SectionStoreFinderRecord = 'SectionStoreFinder',
  SectionTimeMachineRecord = 'SectionTimeMachine',
  SectionThankYouRecord = 'SectionThankYou',
  SectionWalletBalanceRecord = 'SectionWalletBalance'
}

export enum PageBlocks {
  SectionAppSlideRecord = 'SectionAppSlides',
  SectionArticleListRecord = 'SectionArticles',
  SectionAwardListRecord = 'SectionAwards',
  SectionBenefitsListRecord = 'SectionBenefits',
  SectionCardLayerRecord = 'SectionCardLayers',
  SectionCompareRecord = 'SectionCompare',
  SectionCtaRecord = 'SectionCta',
  SectionCustomersListRecord = 'SectionCustomers',
  SectionEducationalRecord = 'SectionEducational',
  SectionFaqRecord = 'SectionFaq',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionHeadlineRecord = 'SectionHeadline',
  SectionNewsletterRecord = 'SectionNewsletter',
  SectionJobBoardRecord = 'SectionJobs',
  SectionLogosListRecord = 'SectionLogos',
  SectionMediaFullscreenRecord = 'SectionMediaFullscreen',
  SectionMobileAppRecord = 'SectionMobileApp',
  SectionNumberRecord = 'SectionNumbers',
  SectionPartnerRecord = 'SectionPartner',
  SectionPressRecord = 'SectionPress',
  SectionRegulationRecord = 'SectionRegulations',
  SectionFeaturedArticleRecord = 'SectionFeaturedArticles',
  SectionStickyScrollRecord = 'SectionStickyScroll',
  SectionTableRecord = 'SectionTable',
  SectionTabsScrollRecord = 'SectionTabsScroll',
  SectionTeamRecord = 'SectionTeam',
  SectionTeaserRecord = 'SectionTeaser',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionValueRecord = 'SectionValue',
  SectionValueInverseRecord = 'SectionValueInverse',
  SectionHelpLinkRecord = 'SectionHelpLinks',
  SectionValuesListRecord = 'SectionValues',
  SectionRelatedHelpArticleListRecord = 'SectionRelatedHelpArticles'
}

export enum MenuBlocks {
  MenuCtaRecord = 'MenuCallToAction',
  MenuGroupRecord = 'MenuGroup'
}
