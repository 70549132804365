import {
  ArticleBlocks,
  DiscoverBlocks,
  HelpTopicBlocks,
  IndexBlocks,
  IndexHeroBlocks,
  PageHeroBlocks,
  PageBlocks,
  MenuBlocks
} from '~/lib/sectionComponents'

export default function () {
  const resolveSectionComponent = (block: string) => {
    if (!block) {
      return
    }

    // @ts-ignore
    const componentName =
      IndexBlocks[block] ||
      IndexHeroBlocks[block] ||
      PageBlocks[block] ||
      PageHeroBlocks[block] ||
      DiscoverBlocks[block] ||
      HelpTopicBlocks[block]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Section component for model [${block}] does not exist.`)
    } else {
      return resolveComponent('Lazy' + componentName)
    }
  }

  const resolveArticleBlockComponent = (block: string) => {
    if (!block) {
      return
    }
    // @ts-ignore
    const componentName = ArticleBlocks[block]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Component for model '${block}' does not exist.`)
    } else {
      return resolveComponent(componentName)
    }
  }

  const resolveMenuBlockComponent = (block: string) => {
    if (!block) {
      return
    }
    // @ts-ignore
    const componentName = MenuBlocks[block]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Component for model '${block}' does not exist.`)
    } else {
      return resolveComponent(componentName)
    }
  }

  return {
    resolveArticleBlockComponent,
    resolveSectionComponent,
    resolveMenuBlockComponent
  }
}
